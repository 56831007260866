import React from 'react';
import {graphql, StaticQuery} from 'gatsby';

import Hero from 'components/Hero.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tile from 'components/Tile.js';
import i18n from 'data/i18n.en.json';

export default class aboutUs extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        content: wordpressPage(wordpress_id: {eq: 111}) {
                            acf {
                                master_image
                                subtitle
                                title
                            }
                            content
                            title
                        }
                        blogLink: wordpressCategory(
                            wordpress_id: {eq: 106132}
                        ) {
                            path
                        }
                    }
                `}
                render={(data) => (
                    <Layout className="contain">
                        <Meta
                            description={data.content.acf.subtitle}
                            title={data.content.title}
                        />
                        <Hero
                            image={data.content.acf.master_image}
                            subtitle={data.content.acf.subtitle}
                            title={
                                data.content.acf.title
                                    ? data.content.acf.title
                                    : data.content.title
                            }
                        />
                        <nav className="tiles">
                            <Tile
                                copy={i18n.aboutTiles.leadership}
                                icon="iconLeadership"
                                title={i18n.aboutTiles.leadershipTitle}
                                to="/company/leadership/"
                            />
                            <Tile
                                copy={i18n.aboutTiles.careers}
                                icon="iconCareers"
                                title={i18n.aboutTiles.careersTitle}
                                to="/company/careers/"
                            />
                            <Tile
                                copy={i18n.aboutTiles.blog}
                                icon="iconBlog"
                                title={i18n.aboutTiles.blogTitle}
                                to={data.blogLink.path}
                            />
                        </nav>
                        {data.content.content && (
                            <section
                                className="blocks"
                                dangerouslySetInnerHTML={{
                                    __html: data.content.content,
                                }}
                            />
                        )}
                    </Layout>
                )}
            />
        );
    }
}
